import React, { useState } from 'react';
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import Hero from './components/sections/Hero';
import About from './components/sections/About';
import Skills from './components/sections/Skills';
import Contact from './components/sections/Contact';
import Projects from './components/sections/Projects'
import LoadingScreen from './components/ui/LoadingScreen';
import './styles/index.css';

const App = () => {
  const [activeSection, setActiveSection] = useState('home');
  const [isLoading, setIsLoading] = useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-black to-gray-900 text-white">
      <Navbar activeSection={activeSection} setActiveSection={setActiveSection} />
      
      <main className="pt-16">
        <Hero isVisible={activeSection === 'home'} />
        <About isVisible={activeSection === 'about'} />
        <Skills isVisible={activeSection === 'skills'} />
        <Projects isVisible={activeSection === 'projects'} />
        <Contact isVisible={activeSection === 'contact'} />
      </main>

      <Footer />
    </div>
  );
};

export default App;