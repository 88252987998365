import React from 'react';
import { FaGithub, FaInstagram, FaCode, FaDatabase, FaServer } from "react-icons/fa";
import { HiMail } from "react-icons/hi";
import profileImage from './logo.jpg';
import LearningSection from './LearningSection';

const Hero = ({ isVisible }) => {
  if (!isVisible) return null;

  return (
    <section className="min-h-screen relative overflow-hidden bg-gradient-to-b from-black to-gray-900">
      {/* Animated Background Elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute w-96 h-96 bg-purple-500/10 rounded-full blur-3xl animate-float left-0 top-0"></div>
        <div className="absolute w-96 h-96 bg-pink-500/10 rounded-full blur-3xl animate-float-delayed right-0 bottom-0"></div>
      </div>

      {/* Main Content */}
      <div className="relative min-h-screen flex flex-col items-center px-4">
        <div className="text-center pt-16">
          {/* Profile Image */}
          <div className="mb-8 relative inline-block">
            <div className="w-32 h-32 rounded-full bg-gradient-to-r from-purple-500 to-pink-500 p-1">
              <div className="w-full h-full rounded-full overflow-hidden">
                <img
                  src={profileImage}
                  alt="Sanzy Dev"
                  className="w-full h-full object-cover transform hover:scale-110 transition-transform duration-300"
                />
              </div>
            </div>
            {/* Floating Tech Icons */}
            <div className="absolute -top-4 -left-4 animate-float">
              <div className="w-12 h-12 bg-gray-800/80 rounded-lg backdrop-blur-sm flex items-center justify-center">
                <FaCode className="text-2xl text-purple-400" />
              </div>
            </div>
            <div className="absolute -bottom-4 -right-4 animate-float-delayed">
              <div className="w-12 h-12 bg-gray-800/80 rounded-lg backdrop-blur-sm flex items-center justify-center">
                <FaDatabase className="text-2xl text-pink-400" />
              </div>
            </div>
            <div className="absolute -right-8 top-1/2 -translate-y-1/2 animate-float">
              <div className="w-12 h-12 bg-gray-800/80 rounded-lg backdrop-blur-sm flex items-center justify-center">
                <FaServer className="text-2xl text-purple-400" />
              </div>
            </div>
          </div>

          {/* Name and Title with Enhanced Animation */}
          <div className="relative">
            <h1 className="text-6xl font-bold mb-4 animate-fade-in">
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600 hover:from-pink-600 hover:to-purple-400 transition-all duration-300">
                Sanzy Dev
              </span>
            </h1>
            <h2 className="text-3xl mb-4 animate-fade-in-delay bg-clip-text text-transparent bg-gradient-to-r from-gray-100 to-gray-300">
              FullStack Developer
            </h2>
            <p className="text-lg text-gray-400 mb-8 animate-fade-in-delay max-w-2xl mx-auto">
              Crafting elegant solutions through code. Specialized in full-stack development 
              with a passion for creating seamless user experiences.
            </p>
          </div>

          {/* Social Links with Enhanced Hover Effects */}
          <div className="flex justify-center space-x-6 mb-12 animate-fade-in-delay-2">
            <a
              href="https://github.com/sanzydev"
              target="_blank"
              rel="noopener noreferrer"
              className="group relative"
            >
              <div className="absolute inset-0 bg-purple-500 rounded-lg blur opacity-20 group-hover:opacity-100 transition-opacity"></div>
              <div className="relative p-4 bg-gray-800/80 rounded-lg backdrop-blur-sm transform hover:scale-110 hover:-translate-y-1 transition-all duration-300">
                <FaGithub className="text-2xl text-white group-hover:text-purple-400" />
              </div>
            </a>
            <a
              href="https://www.instagram.com/sanzydev/"
              target="_blank"
              rel="noopener noreferrer"
              className="group relative"
            >
              <div className="absolute inset-0 bg-purple-500 rounded-lg blur opacity-20 group-hover:opacity-100 transition-opacity"></div>
              <div className="relative p-4 bg-gray-800/80 rounded-lg backdrop-blur-sm transform hover:scale-110 hover:-translate-y-1 transition-all duration-300">
                <FaInstagram className="text-2xl text-white group-hover:text-purple-400" />
              </div>
            </a>
            <a
              href="mailto:support@sanzy.id"
              className="group relative"
            >
              <div className="absolute inset-0 bg-purple-500 rounded-lg blur opacity-20 group-hover:opacity-100 transition-opacity"></div>
              <div className="relative p-4 bg-gray-800/80 rounded-lg backdrop-blur-sm transform hover:scale-110 hover:-translate-y-1 transition-all duration-300">
                <HiMail className="text-2xl text-white group-hover:text-purple-400" />
              </div>
            </a>
          </div>
        </div>

        {/* Learning Section Integration */}
        <div className="w-full max-w-7xl mx-auto">
          <LearningSection />
        </div>
      </div>

      {/* Animations */}
      <style jsx>{`
        @keyframes float {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(-10px); }
        }
        
        @keyframes float-delayed {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(-10px); }
        }
        
        .animate-float {
          animation: float 3s ease-in-out infinite;
        }
        
        .animate-float-delayed {
          animation: float 3s ease-in-out infinite;
          animation-delay: 1.5s;
        }
        
        .animate-fade-in {
          animation: fadeIn 1s ease-out forwards;
        }
        
        .animate-fade-in-delay {
          animation: fadeIn 1s ease-out 0.3s forwards;
          opacity: 0;
        }
        
        .animate-fade-in-delay-2 {
          animation: fadeIn 1s ease-out 0.6s forwards;
          opacity: 0;
        }
        
        @keyframes fadeIn {
          from { opacity: 0; transform: translateY(20px); }
          to { opacity: 1; transform: translateY(0); }
        }
      `}</style>
    </section>
  );
};

export default Hero;