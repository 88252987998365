import React, { useState } from 'react';
import { 
  FaHtml5, FaJs, FaNodeJs, FaPython, FaReact, FaVuejs, 
  FaMobile, FaAndroid, FaApple, FaDatabase, FaDocker, FaAws,
  FaJava, FaPhp, FaRust 
} from "react-icons/fa";
import { 
  SiTypescript, SiFlutter, SiMongodb, 
  SiKubernetes, SiFirebase, SiRuby, SiCsharp, SiSwift,
  SiDjango, SiSpring, SiLaravel
} from "react-icons/si";
import { FaGolang } from "react-icons/fa6";
import { learningMaterials } from './LearningMaterials';
import './LearningSection.css';

const LearningSection = () => {
  const [activeCategory, setActiveCategory] = useState('frontend');
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [isChanging, setIsChanging] = useState(false);

  const categories = {
    frontend: 'Frontend Development',
    backend: 'Backend Development',
    mobile: 'Mobile Development',
    database: 'Database',
    devops: 'DevOps',
    cloudServices: 'Cloud Services'
  };

  const handleCategoryChange = (category) => {
    setIsChanging(true);
    setSelectedLanguage(null);
    
    // Small delay before changing category for smooth transition
    setTimeout(() => {
      setActiveCategory(category);
      setTimeout(() => setIsChanging(false), 100);
    }, 300);
  };

  const getIcon = (iconName) => {
    const icons = {
      FaHtml5: <FaHtml5 className="text-orange-500" />,
      FaJs: <FaJs className="text-yellow-400" />,
      FaReact: <FaReact className="text-blue-400" />,
      FaVuejs: <FaVuejs className="text-green-500" />,
      FaNodeJs: <FaNodeJs className="text-green-600" />,
      FaPython: <FaPython className="text-blue-500" />,
      FaMobile: <FaMobile className="text-gray-400" />,
      FaAndroid: <FaAndroid className="text-green-500" />,
      FaApple: <FaApple className="text-gray-300" />,
      FaDatabase: <FaDatabase className="text-blue-400" />,
      FaDocker: <FaDocker className="text-blue-500" />,
      FaAws: <FaAws className="text-orange-500" />,
      FaJava: <FaJava className="text-red-500" />,
      FaPhp: <FaPhp className="text-purple-500" />,
      FaRust: <FaRust className="text-orange-600" />,
      SiTypescript: <SiTypescript className="text-blue-500" />,
      SiGolang: <FaGolang className="text-blue-400" />,
      SiFlutter: <SiFlutter className="text-blue-400" />,
      SiMongodb: <SiMongodb className="text-green-500" />,
      SiKubernetes: <SiKubernetes className="text-blue-600" />,
      SiFirebase: <SiFirebase className="text-orange-500" />,
      SiRuby: <SiRuby className="text-red-600" />,
      SiCsharp: <SiCsharp className="text-purple-600" />,
      SiSwift: <SiSwift className="text-orange-500" />,
      SiDjango: <SiDjango className="text-green-800" />,
      SiSpring: <SiSpring className="text-green-600" />,
      SiLaravel: <SiLaravel className="text-red-500" />
    };
    return icons[iconName] || <div className="text-gray-400">•</div>;
  };

  const handleLanguageClick = (language) => {
    setSelectedLanguage(selectedLanguage?.language === language.language ? null : language);
  };

  return (
    <div className="w-full max-w-6xl mx-auto px-4 py-16">
      {/* Section Title with Glowing Effect */}
      <div className="relative mb-12 text-center">
        <h2 className="text-4xl font-bold mb-4 bg-gradient-to-r from-purple-400 to-pink-600 bg-clip-text text-transparent">
          Learning Path
        </h2>
        <p className="text-gray-400 max-w-2xl mx-auto">
          Explore our comprehensive learning paths for different technologies and frameworks.
          Select a category to begin your journey into software development.
        </p>
      </div>

      {/* Category Navigation with Animated Underline */}
      <div className="flex flex-wrap justify-center gap-4 mb-12">
        {Object.entries(categories).map(([key, label]) => (
          <button
            key={key}
            onClick={() => handleCategoryChange(key)}
            className={`px-4 py-2 text-lg font-semibold transition-colors duration-300 ${
              activeCategory === key
                ? 'text-purple-500 border-b-2 border-purple-500'
                : 'text-gray-400 border-b-2 border-transparent hover:text-purple-500 hover:border-purple-500'
            }`}
          >
            {label}
          </button>
        ))}
      </div>

      {/* Animated Language Grid */}
      <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8 transition-all duration-300 ${
        isChanging ? 'opacity-0 transform translate-y-4' : 'opacity-100 transform translate-y-0'
      }`}>
        {learningMaterials[activeCategory]?.map((language, index) => (
          <div
            key={language.language}
            onClick={() => handleLanguageClick(language)}
            className={`p-6 rounded-lg cursor-pointer transform transition-all duration-500 hover:scale-105
              ${isChanging ? 'opacity-0 translate-y-4' : 'opacity-100 translate-y-0'}
              ${selectedLanguage?.language === language.language
                ? 'bg-purple-500/20 border-2 border-purple-500 shadow-xl'
                : 'bg-gray-800/50 hover:bg-gray-800/80 border-2 border-transparent hover:border-purple-500/50'}
            `}
            style={{
              transitionDelay: `${index * 100}ms`,
              animationDelay: `${index * 100}ms`
            }}
          >
            <div className="flex items-center space-x-4 mb-4">
              <div className="text-3xl transform transition-transform duration-300 hover:scale-110">
                {getIcon(language.icon)}
              </div>
              <h3 className="text-xl font-semibold text-white">{language.language}</h3>
            </div>
            <div className="space-y-2">
              {language.topics.map((topic, topicIndex) => (
                <div
                  key={topic}
                  className="text-gray-400 flex items-center space-x-2 transform transition-all duration-300"
                  style={{ 
                    transitionDelay: `${topicIndex * 50}ms`,
                    opacity: isChanging ? 0 : 1,
                    transform: isChanging ? 'translateX(-10px)' : 'translateX(0)'
                  }}
                >
                  <span className="w-2 h-2 bg-purple-500 rounded-full"></span>
                  <span>{topic}</span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* Animated Language Details */}
      {selectedLanguage && (
        <div className="mt-8 transform transition-all duration-500">
          <div className="bg-gray-800/50 rounded-lg border-2 border-purple-500/50 overflow-hidden
            animate-slideUp hover:shadow-2xl transition-all duration-300">
            <div className="p-8 bg-gradient-to-r from-purple-500/10 to-pink-500/10">
              <div className="flex items-center space-x-4 mb-6 animate-fadeIn">
                <div className="text-4xl transform hover:scale-110 transition-transform duration-300">
                  {getIcon(selectedLanguage.icon)}
                </div>
                <h3 className="text-3xl font-bold text-white">
                  {selectedLanguage.language}
                </h3>
              </div>
              
              <div className="prose prose-invert max-w-none animate-fadeIn">
                <p className="text-gray-300 whitespace-pre-line text-lg">
                  {selectedLanguage.description}
                </p>
              </div>
            </div>

            {selectedLanguage.resources && (
              <div className="p-8 bg-gray-900/50 border-t border-purple-500/20 animate-slideUp">
                <h4 className="text-xl font-semibold text-white mb-4">Learning Resources</h4>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {selectedLanguage.resources.map((resource, index) => (
                    <a
                      key={resource.name}
                      href={resource.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="group flex items-center space-x-3 p-4 rounded-lg bg-gray-800/50 
                        hover:bg-purple-500/20 transition-all duration-300 transform hover:scale-105"
                      style={{ animationDelay: `${index * 100}ms` }}
                    >
                      <span className="text-purple-400 group-hover:text-purple-300 transition-colors duration-300">→</span>
                      <span className="text-gray-300 group-hover:text-white transition-colors duration-300">{resource.name}</span>
                    </a>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default LearningSection;