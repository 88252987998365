import React from 'react';

const LoadingScreen = () => {
  return (
    <div className="h-screen w-full flex items-center justify-center bg-black">
      <div className="text-4xl font-bold text-white animate-pulse">
        SANZY DEV
      </div>
    </div>
  );
};

export default LoadingScreen;