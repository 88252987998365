import React from 'react';
import { FaCode, FaServer, FaMobileAlt, FaDatabase } from 'react-icons/fa';

const About = ({ isVisible }) => {
  if (!isVisible) return null;

  const services = [
    {
      icon: <FaCode className="text-4xl mb-4 text-purple-500" />,
      title: 'Frontend Development',
      description: 'Building responsive and interactive user interfaces using modern frameworks like React, Vue, and Angular.'
    },
    {
      icon: <FaServer className="text-4xl mb-4 text-purple-500" />,
      title: 'Backend Development',
      description: 'Developing robust server-side applications using Node.js, Express, and other modern technologies.'
    },
    {
      icon: <FaMobileAlt className="text-4xl mb-4 text-purple-500" />,
      title: 'Mobile Development',
      description: 'Creating cross-platform mobile applications using React Native and Flutter.'
    },
    {
      icon: <FaDatabase className="text-4xl mb-4 text-purple-500" />,
      title: 'Database Design',
      description: 'Designing and implementing efficient database structures using MongoDB, PostgreSQL, and MySQL.'
    }
  ];

  return (
    <section className="min-h-screen relative overflow-hidden bg-gradient-to-b from-black to-gray-900">
      {/* Animated Background Elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute w-96 h-96 bg-purple-500/10 rounded-full blur-3xl animate-float left-0 top-0"></div>
        <div className="absolute w-96 h-96 bg-pink-500/10 rounded-full blur-3xl animate-float-delayed right-0 bottom-0"></div>
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        <div className="text-center mb-16 animate-fade-in">
          <h2 className="text-4xl font-bold mb-4">
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
              About Me
            </span>
          </h2>
          <div className="w-16 h-1 bg-gradient-to-r from-purple-500 to-pink-500 mx-auto rounded-full mb-8"></div>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto leading-relaxed">
            I'm a passionate Full Stack Developer with expertise in building modern web applications.
            With 5+ years of experience in software development, I focus on creating efficient,
            scalable, and user-friendly solutions that solve real-world problems.
          </p>
        </div>

        {/* Experience Section */}
        <div className="mb-16 animate-fade-in-delay">
          <h3 className="text-2xl font-bold mb-8 text-center text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
            Professional Journey
          </h3>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="bg-gray-800/50 p-6 rounded-lg backdrop-blur-sm hover:transform hover:scale-105 transition duration-300 hover:shadow-xl hover:shadow-purple-500/10">
              <h4 className="text-xl font-semibold mb-2 text-purple-400">Work Experience</h4>
              <ul className="space-y-4">
                <li>
                  <div className="text-lg font-medium">Senior Full Stack Developer</div>
                  <div className="text-gray-400">Tech Company Inc. | 2020 - Present</div>
                  <p className="text-gray-300 mt-2">Leading development of enterprise web applications and mentoring junior developers.</p>
                </li>
                <li>
                  <div className="text-lg font-medium">Full Stack Developer</div>
                  <div className="text-gray-400">Digital Solutions Ltd. | 2018 - 2020</div>
                  <p className="text-gray-300 mt-2">Developed and maintained multiple client projects using modern web technologies.</p>
                </li>
              </ul>
            </div>

            <div className="bg-gray-800/50 p-6 rounded-lg backdrop-blur-sm hover:transform hover:scale-105 transition duration-300 hover:shadow-xl hover:shadow-purple-500/10">
              <h4 className="text-xl font-semibold mb-2 text-purple-400">Education</h4>
              <ul className="space-y-4">
                <li>
                  <div className="text-lg font-medium">Master's in Computer Science</div>
                  <div className="text-gray-400">Tech University | 2016 - 2018</div>
                  <p className="text-gray-300 mt-2">Specialized in Software Engineering and Distributed Systems.</p>
                </li>
                <li>
                  <div className="text-lg font-medium">Bachelor's in Computer Science</div>
                  <div className="text-gray-400">State University | 2012 - 2016</div>
                  <p className="text-gray-300 mt-2">Foundation in programming, algorithms, and software development.</p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Services Section */}
        <div className="animate-fade-in-delay-2">
          <h3 className="text-2xl font-bold mb-8 text-center text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
            What I Do
          </h3>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
            {services.map((service, index) => (
              <div
                key={index}
                className="bg-gray-800/50 p-6 rounded-lg text-center backdrop-blur-sm hover:transform hover:scale-105 transition duration-300 hover:shadow-xl hover:shadow-purple-500/10"
              >
                {service.icon}
                <h4 className="text-xl font-semibold mb-2">{service.title}</h4>
                <p className="text-gray-300">{service.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Personal Interests */}
        <div className="mt-16 text-center animate-fade-in-delay-2">
          <h3 className="text-2xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
            Beyond Coding
          </h3>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto leading-relaxed">
            When I'm not coding, you'll find me exploring new technologies, contributing to open-source
            projects, or sharing knowledge through technical blog posts. I'm also passionate about
            mentoring aspiring developers and building a supportive tech community.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;