import React, { useState } from 'react';
import { 
  FaEnvelope, 
  FaMapMarkerAlt, 
  FaPhone,
  FaGithub,
  FaTelegram,
  FaInstagram
} from 'react-icons/fa';

const Contact = ({ isVisible }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  if (!isVisible) return null;

  return (
    <section className="min-h-screen py-20 bg-gradient-to-b from-gray-900 to-black opacity-0 animate-fade-in">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header with animation */}
        <div className="text-center mb-16 opacity-0 animate-fade-in-delay">
          <h2 className="text-4xl font-bold mb-4">
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
              Get In Touch
            </span>
          </h2>
          <div className="w-16 h-1 bg-gradient-to-r from-purple-500 to-pink-500 mx-auto rounded-full mb-8"></div>
          <p className="text-xl text-gray-300 max-w-2xl mx-auto">
            Have a project in mind or just want to say hi? Feel free to reach out!
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          {/* Contact Information with slide-in animation */}
          <div className="space-y-8 opacity-0 translate-x-[-50px] animate-slide-in">
            <div className="bg-gray-800/50 backdrop-blur-sm p-8 rounded-2xl transform transition-all duration-300 hover:scale-[1.02] hover:shadow-xl hover:shadow-purple-500/10">
              <h3 className="text-2xl font-semibold mb-6 text-purple-400">Contact Information</h3>
              
              <div className="space-y-6">
                {/* Contact items with staggered animations */}
                <div className="flex items-center space-x-4 opacity-0 animate-fade-in" style={{animationDelay: '0.3s'}}>
                  <div className="w-12 h-12 bg-purple-500/10 rounded-xl flex items-center justify-center transform transition-all duration-300 hover:rotate-12">
                    <FaEnvelope className="text-2xl text-purple-400" />
                  </div>
                  <div>
                    <p className="text-gray-400">Email</p>
                    <a href="mailto:contact@sanzydev.com" className="text-lg hover:text-purple-400 transition-colors">
                      contact@sanzy.id
                    </a>
                  </div>
                </div>

                <div className="flex items-center space-x-4 opacity-0 animate-fade-in" style={{animationDelay: '0.4s'}}>
                  <div className="w-12 h-12 bg-purple-500/10 rounded-xl flex items-center justify-center transform transition-all duration-300 hover:rotate-12">
                    <FaMapMarkerAlt className="text-2xl text-purple-400" />
                  </div>
                  <div>
                    <p className="text-gray-400">Location</p>
                    <p className="text-lg">Riau, Indonesia</p>
                  </div>
                </div>

                <div className="flex items-center space-x-4 opacity-0 animate-fade-in" style={{animationDelay: '0.5s'}}>
                  <div className="w-12 h-12 bg-purple-500/10 rounded-xl flex items-center justify-center transform transition-all duration-300 hover:rotate-12">
                    <FaPhone className="text-2xl text-purple-400" />
                  </div>
                  <div>
                    <p className="text-gray-400">Phone</p>
                    <p className="text-lg">+62 812-7669-8054</p>
                  </div>
                </div>
              </div>

              {/* Social Links with pop-up animation */}
              <div className="mt-8 pt-8 border-t border-gray-700 opacity-0 animate-fade-in" style={{animationDelay: '0.6s'}}>
                <h4 className="text-lg font-medium mb-4 text-gray-300">Connect with me</h4>
                <div className="flex space-x-4">
                  <a
                    href="https://github.com/sanzydev"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="w-10 h-10 bg-gray-800 rounded-full flex items-center justify-center hover:bg-purple-500 transition-all duration-300 transform hover:scale-110 hover:-translate-y-1"
                  >
                    <FaGithub className="text-xl" />
                  </a>
                  <a
                    href="https://t.me/sanzydevv"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="w-10 h-10 bg-gray-800 rounded-full flex items-center justify-center hover:bg-purple-500 transition-all duration-300 transform hover:scale-110 hover:-translate-y-1"
                  >
                    <FaTelegram className="text-xl" />
                  </a>
                  <a
                    href="https://www.instagram.com/sanzydev"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="w-10 h-10 bg-gray-800 rounded-full flex items-center justify-center hover:bg-purple-500 transition-all duration-300 transform hover:scale-110 hover:-translate-y-1"
                  >
                    <FaInstagram className="text-xl" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Contact Form with slide-in animation */}
          <div className="bg-gray-800/50 backdrop-blur-sm p-8 rounded-2xl opacity-0 translate-x-[50px] animate-slide-in transform transition-all duration-300 hover:scale-[1.01] hover:shadow-xl hover:shadow-purple-500/10">
            <h3 className="text-2xl font-semibold mb-6 text-purple-400">Send Message</h3>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="transform transition-all duration-300 hover:translate-y-[-2px]">
                  <label className="block text-sm font-medium text-gray-400 mb-2">
                    Your Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full px-4 py-3 rounded-lg bg-gray-700/50 border border-gray-600 focus:outline-none focus:border-purple-500 transition-all duration-300 focus:scale-[1.02]"
                    placeholder="Sanzy"
                  />
                </div>
                <div className="transform transition-all duration-300 hover:translate-y-[-2px]">
                  <label className="block text-sm font-medium text-gray-400 mb-2">
                    Your Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full px-4 py-3 rounded-lg bg-gray-700/50 border border-gray-600 focus:outline-none focus:border-purple-500 transition-all duration-300 focus:scale-[1.02]"
                    placeholder="example@example.com"
                  />
                </div>
              </div>

              <div className="transform transition-all duration-300 hover:translate-y-[-2px]">
                <label className="block text-sm font-medium text-gray-400 mb-2">
                  Subject
                </label>
                <input
                  type="text"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  className="w-full px-4 py-3 rounded-lg bg-gray-700/50 border border-gray-600 focus:outline-none focus:border-purple-500 transition-all duration-300 focus:scale-[1.02]"
                  placeholder="Project Inquiry"
                />
              </div>

              <div className="transform transition-all duration-300 hover:translate-y-[-2px]">
                <label className="block text-sm font-medium text-gray-400 mb-2">
                  Message
                </label>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows={6}
                  className="w-full px-4 py-3 rounded-lg bg-gray-700/50 border border-gray-600 focus:outline-none focus:border-purple-500 transition-all duration-300 focus:scale-[1.02] resize-none"
                  placeholder="Your message here..."
                ></textarea>
              </div>

              <button
                type="submit"
                className="w-full py-4 px-6 rounded-lg bg-gradient-to-r from-purple-500 to-pink-500 text-white font-medium hover:from-purple-600 hover:to-pink-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-gray-900 transform hover:scale-[1.02] transition-all duration-300 hover:shadow-lg hover:shadow-purple-500/25"
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
