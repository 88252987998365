import React from 'react';
import { FaHtml5, FaCss3Alt, FaJs, FaReact, FaNode, FaPython, FaDatabase, FaGit, FaDocker } from 'react-icons/fa';
import { SiTypescript, SiMongodb, SiPostgresql, SiExpress, SiTailwindcss } from 'react-icons/si';

const Skills = ({ isVisible }) => {
  if (!isVisible) return null;

  const skillCategories = [
    {
      title: "Frontend Development",
      skills: [
        { name: "HTML5", icon: <FaHtml5 />, level: 90, color: "text-orange-500" },
        { name: "CSS3", icon: <FaCss3Alt />, level: 85, color: "text-blue-500" },
        { name: "JavaScript", icon: <FaJs />, level: 90, color: "text-yellow-400" },
        { name: "React", icon: <FaReact />, level: 88, color: "text-cyan-400" },
        { name: "TypeScript", icon: <SiTypescript />, level: 80, color: "text-blue-600" },
        { name: "Tailwind CSS", icon: <SiTailwindcss />, level: 85, color: "text-teal-400" },
      ]
    },
    {
      title: "Backend Development",
      skills: [
        { name: "Node.js", icon: <FaNode />, level: 85, color: "text-green-500" },
        { name: "Express.js", icon: <SiExpress />, level: 82, color: "text-gray-400" },
        { name: "Python", icon: <FaPython />, level: 80, color: "text-blue-500" },
        { name: "MongoDB", icon: <SiMongodb />, level: 85, color: "text-green-400" },
        { name: "PostgreSQL", icon: <SiPostgresql />, level: 80, color: "text-blue-400" },
      ]
    },
    {
      title: "Tools & Technologies",
      skills: [
        { name: "Git", icon: <FaGit />, level: 88, color: "text-orange-600" },
        { name: "Docker", icon: <FaDocker />, level: 75, color: "text-blue-500" },
        { name: "Database Design", icon: <FaDatabase />, level: 85, color: "text-purple-500" },
      ]
    }
  ];

  return (
    <section className="min-h-screen relative overflow-hidden bg-gradient-to-b from-black to-gray-900">
      {/* Animated Background Elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute w-96 h-96 bg-purple-500/10 rounded-full blur-3xl animate-float left-0 top-0"></div>
        <div className="absolute w-96 h-96 bg-pink-500/10 rounded-full blur-3xl animate-float-delayed right-0 bottom-0"></div>
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        {/* Header */}
        <div className="text-center mb-16 animate-fade-in">
          <h2 className="text-4xl font-bold mb-4">
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
              Technical Skills
            </span>
          </h2>
          <div className="w-16 h-1 bg-gradient-to-r from-purple-500 to-pink-500 mx-auto rounded-full mb-8"></div>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto">
            A comprehensive overview of my technical expertise and proficiency in various technologies.
          </p>
        </div>

        {/* Skills Categories */}
        <div className="space-y-16">
          {skillCategories.map((category, categoryIndex) => (
            <div 
              key={category.title}
              className="animate-fade-in"
              style={{ animationDelay: `${categoryIndex * 200}ms` }}
            >
              <h3 className="text-2xl font-bold mb-8 text-center text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
                {category.title}
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {category.skills.map((skill, index) => (
                  <div
                    key={skill.name}
                    className="bg-gray-800/50 backdrop-blur-sm rounded-lg p-6 transform hover:scale-105 transition-all duration-300 hover:shadow-xl hover:shadow-purple-500/20"
                    style={{ animationDelay: `${index * 100}ms` }}
                  >
                    <div className="flex items-center mb-4">
                      <div className={`text-3xl ${skill.color} mr-3 transform transition-all duration-300 hover:rotate-12`}>
                        {skill.icon}
                      </div>
                      <h4 className="text-xl font-semibold">{skill.name}</h4>
                    </div>
                    
                    <div className="relative pt-1">
                      <div className="flex items-center justify-between mb-2">
                        <div className="text-sm text-gray-400">Proficiency</div>
                        <div className="text-sm text-gray-400">{skill.level}%</div>
                      </div>
                      <div className="overflow-hidden h-2 text-xs flex rounded bg-gray-700">
                        <div
                          style={{ width: `${skill.level}%` }}
                          className="animate-width-expand shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-gradient-to-r from-purple-500 to-pink-500"
                        ></div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        {/* Additional Info */}
        <div className="mt-16 text-center animate-fade-in">
          <p className="text-gray-400 italic">
            * Skills are continuously being updated and improved
          </p>
        </div>
      </div>
    </section>
  );
};

export default Skills;