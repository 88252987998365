import React from 'react';
import { FaGithub, FaExternalLinkAlt, FaBolt, FaSearchengin, FaRobot } from 'react-icons/fa';
import alinaImage from './projects/alina.jpg'; 

const Projects = ({ isVisible }) => {
  if (!isVisible) return null;

  const project = {
    title: "Alina AI",
    description: "An advanced AI-powered search engine that revolutionizes the way we find information. Utilizing cutting-edge artificial intelligence and machine learning algorithms, Alina AI delivers lightning-fast, highly accurate search results with exceptional precision.",
    features: [
      {
        icon: <FaBolt className="text-yellow-400" />,
        title: "Lightning Fast Results",
        description: "Advanced caching and optimization techniques deliver search results in milliseconds."
      },
      {
        icon: <FaSearchengin className="text-blue-400" />,
        title: "Intelligent Search",
        description: "Smart algorithms understand context and user intent for more relevant results."
      },
      {
        icon: <FaRobot className="text-purple-400" />,
        title: "AI-Powered Analysis",
        description: "Real-time data processing and analysis for enhanced search accuracy."
      }
    ],
    technologies: ["React", "Node.js", "TensorFlow", "PyTorch", "OpenAI API", "MongoDB"],
    demoUrl: "",
    githubUrl: "https://github.com/sanzydev/"
  };

  return (
    <section className="min-h-screen relative overflow-hidden bg-gradient-to-b from-black to-gray-900">
      {/* Animated Background Elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute w-96 h-96 bg-purple-500/10 rounded-full blur-3xl animate-float left-0 top-0"></div>
        <div className="absolute w-96 h-96 bg-pink-500/10 rounded-full blur-3xl animate-float-delayed right-0 bottom-0"></div>
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        {/* Header */}
        <div className="text-center mb-16 animate-fade-in">
          <h2 className="text-4xl font-bold mb-4">
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
              Featured Project
            </span>
          </h2>
          <div className="w-16 h-1 bg-gradient-to-r from-purple-500 to-pink-500 mx-auto rounded-full mb-8"></div>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto">
            Showcasing innovation in AI-powered search technology.
          </p>
        </div>

        {/* Project Card */}
        <div className="bg-gray-800/50 rounded-xl overflow-hidden backdrop-blur-sm hover:shadow-xl hover:shadow-purple-500/20 transition-all duration-300 animate-fade-in max-w-5xl mx-auto">
          {/* Project Image */}
          <div className="relative overflow-hidden h-96">
            <img
              src={alinaImage}
              alt="Alina AI Project"
              className="w-full h-full object-cover transform hover:scale-110 transition-transform duration-500"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent">
              <div className="absolute bottom-6 left-6">
                <h3 className="text-4xl font-bold text-white mb-2">{project.title}</h3>
                <div className="flex space-x-4">
                  {project.demoUrl && (
                    <a
                      href={project.demoUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="px-4 py-2 bg-purple-500 rounded-lg hover:bg-purple-600 transition-colors duration-300 flex items-center space-x-2"
                    >
                      <FaExternalLinkAlt /> <span>Live Demo</span>
                    </a>
                  )}
                  <a
                    href={project.githubUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="px-4 py-2 bg-gray-700 rounded-lg hover:bg-gray-600 transition-colors duration-300 flex items-center space-x-2"
                  >
                    <FaGithub /> <span>View Code</span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Project Info */}
          <div className="p-8">
            <p className="text-gray-300 text-lg mb-8 leading-relaxed">
              {project.description}
            </p>

            {/* Features Grid */}
            <div className="grid md:grid-cols-3 gap-6 mb-8">
              {project.features.map((feature, index) => (
                <div 
                  key={feature.title}
                  className="bg-gray-900/50 p-6 rounded-xl hover:transform hover:scale-105 transition-all duration-300"
                >
                  <div className="text-3xl mb-4">{feature.icon}</div>
                  <h4 className="text-xl font-semibold mb-2 text-white">{feature.title}</h4>
                  <p className="text-gray-400">{feature.description}</p>
                </div>
              ))}
            </div>

            {/* Technologies */}
            <div>
              <h4 className="text-xl font-semibold mb-4 text-purple-400">Technologies Used</h4>
              <div className="flex flex-wrap gap-2">
                {project.technologies.map((tech) => (
                  <span
                    key={tech}
                    className="px-4 py-2 bg-purple-500/20 text-purple-300 rounded-lg font-medium"
                  >
                    {tech}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;