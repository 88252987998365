// LearningMaterials.js
export const learningMaterials = {
    frontend: [
      {
        language: 'HTML/CSS',
        icon: 'FaHtml5',
        topics: [
          'Semantic HTML5',
          'CSS Flexbox & Grid',
          'Responsive Design',
          'CSS Animations',
          'SASS/SCSS',
          'CSS Architecture'
        ],
        description: `HTML and CSS are fundamental technologies for web development.
        Master modern layout techniques and responsive design principles.
        
        Key Learning Points:
        • Advanced semantic HTML structures
        • Modern CSS features and frameworks
        • Animation and transition techniques
        • CSS preprocessors and architecture
        • Performance optimization
        • Cross-browser compatibility`,
        resources: [
          { name: 'MDN Web Docs', url: 'https://developer.mozilla.org' },
          { name: 'CSS-Tricks', url: 'https://css-tricks.com' },
          { name: 'Web.dev', url: 'https://web.dev' }
        ]
      },
      {
        language: 'JavaScript',
        icon: 'FaJs',
        topics: [
          'ES6+ Features',
          'DOM Manipulation',
          'Async Programming',
          'Design Patterns',
          'Testing',
          'Performance'
        ],
        description: `JavaScript is the backbone of modern web development.
        From basic interactivity to complex applications.
        
        Advanced Topics:
        • Functional Programming
        • Object-Oriented JavaScript
        • Memory Management
        • Event Loop and Async
        • Modern API Integration
        • Security Best Practices`,
        resources: [
          { name: 'JavaScript.info', url: 'https://javascript.info' },
          { name: 'V8 Dev', url: 'https://v8.dev' }
        ]
      },
      {
        language: 'React',
        icon: 'FaReact',
        topics: [
          'Hooks & Custom Hooks',
          'State Management',
          'Performance Optimization',
          'Server Components',
          'Testing Strategies',
          'Architecture Patterns'
        ],
        description: `React is a powerful library for building user interfaces.
        Learn modern React patterns and best practices.
        
        Key Concepts:
        • Component Architecture
        • State Management Solutions
        • React Server Components
        • Performance Optimization
        • Testing Strategies
        • Modern React Patterns`,
        resources: [
          { name: 'React Dev', url: 'https://react.dev' },
          { name: 'NextJS Docs', url: 'https://nextjs.org/docs' }
        ]
      },
      {
        language: 'Vue.js',
        icon: 'FaVuejs',
        topics: [
          'Vue 3 Composition API',
          'State Management',
          'Nuxt.js',
          'Testing',
          'Performance',
          'Best Practices'
        ]
      }
    ],
    backend: [
      {
        language: 'Node.js',
        icon: 'FaNodeJs',
        topics: [
          'Express/Fastify',
          'GraphQL',
          'Microservices',
          'Security',
          'Testing',
          'Performance'
        ],
        description: `Node.js enables server-side JavaScript development.
        Build scalable and high-performance applications.
        
        Advanced Topics:
        • Microservices Architecture
        • Real-time Applications
        • Security Best Practices
        • API Design Patterns
        • Performance Optimization
        • Testing Strategies`,
        resources: [
          { name: 'Node.js Docs', url: 'https://nodejs.org/docs' },
          { name: 'Express.js', url: 'https://expressjs.com' }
        ]
      },
      {
        language: 'Python',
        icon: 'FaPython',
        topics: [
          'Django/FastAPI',
          'Data Science',
          'Machine Learning',
          'Automation',
          'Testing',
          'Best Practices'
        ],
        description: `Python is versatile for web, data science, and automation.
        From web apps to AI/ML applications.
        
        Key Areas:
        • Web Development
        • Data Analysis
        • Machine Learning
        • Automation
        • Security
        • Best Practices`,
        resources: [
          { name: 'Python Docs', url: 'https://docs.python.org' },
          { name: 'FastAPI', url: 'https://fastapi.tiangolo.com' }
        ]
      }
    ],
    mobile: [
      {
        language: 'React Native',
        icon: 'FaMobile',
        topics: [
          'Cross-platform Development',
          'Native Modules',
          'Performance',
          'Navigation',
          'State Management',
          'Testing'
        ],
        description: `React Native for building native mobile apps.
        Create iOS and Android apps with JavaScript.
        
        Key Concepts:
        • Native Components
        • Platform-specific Code
        • Performance Optimization
        • Navigation Patterns
        • State Management
        • Testing Strategies`,
        resources: [
          { name: 'React Native', url: 'https://reactnative.dev' },
          { name: 'Expo', url: 'https://expo.dev' }
        ]
      },
      {
        language: 'Flutter',
        icon: 'SiFlutter',
        topics: [
          'Dart Programming',
          'Widget System',
          'State Management',
          'Animations',
          'Testing',
          'Performance'
        ],
        description: `Flutter for beautiful native applications.
        Google's UI toolkit for mobile, web, and desktop.
        
        Core Concepts:
        • Dart Language
        • Widget System
        • State Management
        • Custom Animations
        • Testing
        • Performance Tips`,
        resources: [
          { name: 'Flutter Dev', url: 'https://flutter.dev' },
          { name: 'Dart Dev', url: 'https://dart.dev' }
        ]
      },
      {
        language: 'Android Native',
        icon: 'FaAndroid',
        topics: [
          'Kotlin Development',
          'Android SDK',
          'Jetpack Compose',
          'Architecture Patterns',
          'Testing',
          'Performance'
        ],
        description: `Native Android development with Kotlin.
        Build high-performance Android applications.
        
        Key Areas:
        • Kotlin Programming
        • Modern Android Development
        • UI with Jetpack Compose
        • Architecture Components
        • Testing Strategies
        • Performance Optimization`,
        resources: [
          { name: 'Android Developers', url: 'https://developer.android.com' },
          { name: 'Kotlin Dev', url: 'https://kotlinlang.org' }
        ]
      },
      {
        language: 'iOS Native',
        icon: 'FaApple',
        topics: [
          'Swift Programming',
          'iOS SDK',
          'SwiftUI',
          'Architecture',
          'Testing',
          'Performance'
        ],
        description: `Native iOS development with Swift.
        Create powerful Apple ecosystem applications.
        
        Focus Areas:
        • Swift Programming
        • iOS Development
        • SwiftUI
        • Architecture Patterns
        • Testing
        • Performance Tips`,
        resources: [
          { name: 'Swift.org', url: 'https://swift.org' },
          { name: 'iOS Dev', url: 'https://developer.apple.com/ios' }
        ]
      }
    ],
    database: [
      {
        language: 'SQL',
        icon: 'FaDatabase',
        topics: [
          'Database Design',
          'Query Optimization',
          'Indexing',
          'Transactions',
          'Security',
          'Performance'
        ]
      },
      {
        language: 'MongoDB',
        icon: 'SiMongodb',
        topics: [
          'NoSQL Design',
          'Aggregation',
          'Indexing',
          'Security',
          'Performance',
          'Best Practices'
        ]
      }
    ],
    devops: [
      {
        language: 'Docker',
        icon: 'FaDocker',
        topics: [
          'Containerization',
          'Docker Compose',
          'Networking',
          'Security',
          'Orchestration',
          'Best Practices'
        ]
      },
      {
        language: 'Kubernetes',
        icon: 'SiKubernetes',
        topics: [
          'Container Orchestration',
          'Scaling',
          'Networking',
          'Security',
          'Monitoring',
          'Best Practices'
        ]
      }
    ],
    cloudServices: [
      {
        language: 'AWS',
        icon: 'FaAws',
        topics: [
          'EC2/Lambda',
          'S3/RDS',
          'CloudFormation',
          'Security',
          'Monitoring',
          'Cost Optimization'
        ]
      },
      {
        language: 'Firebase',
        icon: 'SiFirebase',
        topics: [
          'Authentication',
          'Firestore',
          'Cloud Functions',
          'Hosting',
          'Analytics',
          'Security'
        ]
      }
    ]
  };